.SlideGallery {
  margin-top: -56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SlideGallery__title {
  width: calc(100vw - 20px);
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 121%;
  color: var(--text_primary);
  margin-bottom: 5%;
  padding-left: 20px;
}

@media only screen and (max-width: 320px) {
  .SlideGallery__title {
    padding-left: 10px;
    margin-bottom: 3%;
    font-size: 2rem;
  }
}

.SlideGallery__title span {
  position: relative;
  background: var(--text_primary);
  color: var(--control_tint);
  padding: 0 4px;
}

.SlideGallery__content {
  display: flex;
  justify-content: space-evenly;
  width: calc(100vw - 52px);
  padding: 0 26px;
}

.SlideGallery__content img {
  width: 100%;
  height: 100%;
  max-width: 300px;
}

.GalleryImage {
  width: auto;
  min-height: 42%;
}

@media only screen and (max-width: 1024px) {
  .GalleryImage {
    width: 100%;
  }
}
