.Grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.Contrast {
  /*filter: contrast(1.2);*/
}

.GroupProgress {
  margin-bottom: 12px;
}