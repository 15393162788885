.CellProfileStats__HeaderText .Header__content {
  color: var(--text_muted);
}

.CellProfileStats__HeaderText {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}

.CellProfileStats__HeaderText.SimpleCell {
  min-height: 36px;
  height: 36px;
  padding-right: 12px;
}

.CellProfileStats__HeaderText .Link {
  text-transform: none;
  font-weight: 600;

}

.CellProfileStats__HeaderText__Count {
  color: var(--content_placeholder_text);
  margin-left: 7px;
  font-size: 13px;

}

.CellProfileStats__HeaderText__Text {
  font-weight: 600;
  font-size: 13px;


}
