.parent{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    place-items: center;
}

.parent span{
    padding-left: 12px;
    text-decoration: underline;
}

.box {
    flex: 1 1 150px; /*  Stretching: */
    margin: 5px;
}

.buttonAudioPlayer {
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /*display: grid;*/
    place-items: center;
    outline: none;
    padding-top: 4px;
}