.CalcPanel_Title {
  padding: 0 12px 12px;
  color: var(--text_subhead);
}

.CalcPanel_Result {
  padding: 0 12px 12px 43px;
}

.equation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.equation .Button__in {
  padding: 6px 15px;
  font-weight: 500;
}

.equation > div:first-child {
  margin-bottom: 15px;
}
