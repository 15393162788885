.cardsWrap {
  margin: 12px 0;
}

.CellProfileProgress {
  background: none;
  margin-left: 24px;
  text-align: center;
}

.CellProfileProgress__Images {
  display: grid;
  place-items: center;
}

.CellProfileProgress__Images img {
  height: 72px
}

.CellProfileProgress__Text {
  background: var(--field_background);
  align-content: center;
  margin-top: -73px;
  border-radius: 10px;
  font-size: 12px;
  width: 50%;
}

.CellProfileProgress__Label {
  margin-top: 8px;
  font-size: 9px

}