.MainCardItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 76px;
  height: 92px;
  background: var(--header_background);
}

.MainCardItem:not(:first-child) {
  margin-left: 12px;
}

.MainCardItemImage {
  width: 64px;
  height: 64px;
  border-radius: 10px;
}
