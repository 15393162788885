.CommentCard {
  margin-top: 10px;
  position: relative;
}

.CommentCard__image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.CommentCard__content {
  padding: 12px 16px;
}

.CommentCard__content > div:not(:last-child){
  margin-bottom: 4px;
}

.CommentCard__content .Button {
  padding: 0;
}

.CommentCard__title {
  font-weight: 600;
  font-size: 17px;
}

.CommentCard__text {
  font-size: 15px;
}

.CommentCard__date {
  color: var(--content_placeholder_text);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.CommentCard__button {
  position: absolute;
  right: 12px;
  top: 12px;
  color: var(--accent);
}

.CommentCard__cardScroll {
  padding: 10px;
}
.CommentCard__cardScroll .Card {
}