.SlideConsultant {
  display: flex;
  flex-direction: column;
}

.SlideConsultant:nth-child(2) {
  margin-top: 21px;
}

.SlideConsultant__avatar {
  position: relative;
  width: 96px;
  height: 96px;
  margin-bottom: 11px;
}

.SlideConsultant__avatar img {
  width: 100%;
  height: 100%;
}

.SlideConsultant__avatar::before {
  content: '';
  position: absolute;
  right: 7px;
  bottom: 3px;
  width: 12px;
  height: 12px;
  background: var(--dynamic_green);
  border-radius: 50%;
  border: 3px solid var(--white);
}

.SlideConsultant__stars {
  display: flex;
  justify-content: center;
}

.SlideConsultant__stars .Icon:nth-child(2) {
  margin-top: 3px;
}
