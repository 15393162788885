.DialogHeader_Content {
  margin-left: 10px;
  height: 52px;
}

.DialogHeader_User {
  display: flex;
  align-items: center;
}

.DialogHeader_UserName {
  margin-right: 2px;
}

.DialogHeader_Dropdown {
  color: var(--accent);
}
