.BannerItem_typeColor, .BannerItem_typeImage {
  white-space: pre-wrap;
  padding: 0 0 12px;
}
.BannerItem_typeDefault  {
  white-space: pre-wrap;
  padding: 0 0 12px;
}

.BannerItem_typeColor, .BannerItem_typeImage .Banner__in {
  /*height: 120px;*/
}

.BannerButton.Button--lvl-overlay_primary {
  margin-top: 12px;
  margin-bottom: 6px;
}

.BannerItem_typeColor .Banner__dismissIcon,
.BannerItem_typeImage .Banner__dismissIcon {
  background: transparent;
}

.BannerItem_typeColor .Icon,
.BannerItem_typeImage .Icon {
  width: 24px;
  height: 24px;
  background: no-repeat center/24px url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="like_outline_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M15.992 4.006c-1.451.064-2.753.637-3.881 1.694l-.117.113-.122-.118C10.662 4.576 9.275 4 7.734 4 4.577 4 2 6.56 2 9.717c0 3.088 1.127 4.552 6.182 8.546l2.688 2.098a1.837 1.837 0 002.26 0l2.364-1.843.933-.74C20.965 14.144 22 12.676 22 9.718 22 6.56 19.423 4 16.266 4zm.274 1.794c2.165 0 3.934 1.757 3.934 3.917l-.005.294c-.076 2.156-1.062 3.341-5.509 6.852l-2.663 2.078a.038.038 0 01-.046 0l-2.364-1.843-.874-.691c-4.142-3.31-4.939-4.44-4.939-6.69C3.8 7.557 5.569 5.8 7.734 5.8c1.333 0 2.507.618 3.57 1.915a.9.9 0 001.398-.007C13.739 6.416 14.909 5.8 16.266 5.8z" fill="white" fill-rule="nonzero"></path></g></svg>');
}

.BannerItem_typeColor .Icon svg,
.BannerItem_typeImage .Icon svg {
  display: none !important;
}
