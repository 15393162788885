.SimpleCell__after .ProfileHeader-Btn .Icon {
  padding-right: 2px;
}

.ProfileHeader-Status {
  padding: 6px 12px 0;
}

.ProfileHeader-Status Progress {
  height: 10px;
}

.ProfileHeader-StatusText {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: var(--text_primary);
  text-transform: uppercase;
  margin-bottom: 8px;
}

.ProfileHeader-StatusText span {
  color: var(--text_subhead);
  text-transform: none;
}

.ProfileHeader-Commission.RichCell {
  align-items: center;
}

.ProfileHeader-Commission .Icon {
  margin-right: 18px;
}

.ProfileHeader-Commission .RichCell__children {
  width: calc(100% - 87px);
  flex-grow: 0;
}

.ProfileHeader-Commission .RichCell__after {
  position: absolute;
  top: 3px;
  right: 0;
}

.ProfileHeader-Commission Button {
  margin-top: 9px;
}

.ProfileHeader__About {
  padding: 12px 28px 3px;
  font-size: 15px;
}

.ProfileHeader__About--Text {
  font-size: 11px;
  font-weight: 500;
  color: var(--content_placeholder_text);
  margin-bottom: 4px;
}

.ExecutorsProfileItem__Btn {
  pointer-events: none;
}

.SimpleCell__after .ExecutorsProfileItem__Btn .Icon {
  padding-right: 2px;
}

.ExecutorsProfileItem__Actions {
  display: flex;
  justify-content: flex-end;
  margin: 12px 12px 0 0;
}

.ExecutorsProfileItem__Actions > :last-child {
  margin-left: 12px;
}

.ExecutorsProfileItem__About {
  padding: 12px 28px 12px 28px;
  font-size: 15px;
}

.ExecutorsProfileItem__About--Text {
  font-size: 11px;
  font-weight: 500;
  color: var(--content_placeholder_text);
  margin-bottom: 4px;
}
