.ProfileCategories .Group >:not(.Header):first-child {
  margin-top: 0;
}

.ProfileCategories .CategoryItem__SimpleCell {
  pointer-events: none;
}

.ProfileCategories .FormLayout__row {
  padding-top: 0;
}
