.PublishRequest .Panel__in {
  display: flex;
  flex-direction: column;
}

.PublishRequest .FormLayout {
  flex: 1;
}

.PublishRequest__FileInput .Button__content {
  font-size: 12px;
  line-height: 16px;
}

.PublishRequest__Media {
  padding-bottom: 60px;
}

.PublishImage__FileList  {
  margin-top: 24px
}
