.DialogListItem .RichCell__top {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.DialogListItem .RichCell__content {
  font-size: 17px;
  line-height: 21px;
}

.DialogListItem .RichCell__after {
  position: absolute;
  top: 16px;
  right: 0;
}

.DialogListItem .RichCell__caption {
  position: relative;
  display: flex;
  align-items: baseline;
  font-size: 15px;
  /*line-height: inherit;*/
}

.DialogListItem .RichCell__caption > .Time {
  margin-left: 3px;
  font-size: 13px;
  color: var(--text_tertiary);
}

.DialogListItem .RichCell__caption > .LastMessage {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.DialogListItem .UnreadMessage {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--accent);
}

