.ProfileVideo {
  display: flex;

  align-items: center;
  flex-direction: column;
}

.Block{
}

.Block iframe {
  border: none;
  border-radius: 12px;
}

.Block span {
}

.VideoBlock {
  margin-bottom: 15px;
}
