/*.PreviewImageList {*/
/*  display: flex;*/
/*  margin-bottom: 8px;*/
/*  max-width: 100px;*/
/*}*/

/*.PreviewImage {*/
/*  position: relative;*/
/*}*/

/*.PreviewImage:not(:first-child) {*/
/*  margin-left: 4px;*/
/*}*/

/*.InputImage {*/
/*  width: 80px;*/
/*  height: 80px;*/
/*  border-radius: 12px;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  background-position: center;*/
/*}*/

/*.RemoveImage {*/
/*  position: absolute;*/
/*  right: 2px;*/
/*  top: 2px;*/
/*  color: var(--white);*/
/*  border: 2px solid var(--white);*/
/*  background: var(--float_button_foreground);*/
/*  border-radius: 50%;*/
/*}*/

.PreviewImageList {
  display: flex;
  background-color: var(--background_content);
}

.PreviewImage {
  position: relative;
}

.PreviewImage:not(:first-child) {
  margin-left: 4px;
}

.InputImage {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.RemoveImage {
  position: absolute;
  right: 2px;
  top: 2px;
  color: var(--white);
  border: 2px solid var(--white);
  background: var(--float_button_foreground);
  border-radius: 50%;
}
