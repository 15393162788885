.Epic .MessagePanel .FixedLayout--bottom {
  padding-bottom: 0 !important;
}

.MessagePanel .Panel__in {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.MessagesList {
  display: flex;
  flex-direction: column;
  padding: 0 8px 80px 8px;
  overflow-y: auto;
  flex-wrap: nowrap;
}

.MessageItem {
  display: flex;
  flex-shrink: 0;
}

.MessageItem.Me {
  align-self: flex-end;
}

.MessageItem.Me > .MessageBody {
  background: var(--im_bubble_outgoing);
}

.MessageAvatar {
  margin-right: 8px;
}

.MessageItem:not(:last-child) {
  margin-bottom: 8px;
}

.MessageBody {
  display: block;
  background: var(--im_bubble_incoming);
  border-radius: 18px;
  font-size: 17px;
  line-height: 22px;
  max-width: 275px;
  overflow: hidden;
}

.MessageText {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 7px 50px 7px 12px;
}

.MessageSticker {
  width: 150px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.MessageBody img {
  max-width: 275px;
  display:block;
}

.MessageBody img:not(:first-child) {
  margin-top: 1px;
}

.MessageBody button {
  margin: 7px 50px 7px 12px;
  border: none;
  outline: none;
  background: transparent;
  height: 34px;
}

.MessageBody a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000 !important;
}

.MessageTextFile {
  color: var(--accent);
  margin-left: 7px;
  font-size: 17px;
}

.MessageBill {
  color: var(--text_primary);
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 12px 24px 12px 12px;
}

.MessageDate {
  margin-top: -25px;
  margin-right: 7px;
  opacity: 0.4;
  font-size: 11px;
  line-height: 14px;
  display: inline-block;
  float: right;
  padding: 5px 7px;
  border-radius: 5px;
}

.MessageDateImg {
  margin-top: -34px;
  margin-right: 10px;
  opacity: 0.9;
  font-size: 11px;
  line-height: 14px;
  background-color: black;
  color: white;
  display: inline-block;
  float: right;
  padding: 3px 7px;
  border-radius: 10px;
}

.MessageDateDoctype {
  margin-right: 7px;
  opacity: 0.4;
  font-size: 11px;
  line-height: 14px;
  display: inline-block;
  float: right;
  padding: 5px 7px;
}

.DialogWriteBar {
  display: flex;
  align-items: flex-end;
  background-color: var(--background_content);
  padding-top: 6px;
  padding-bottom: 12px;
}

/* iPhone X and Xs Max */
@media only screen
and (min-device-width: 375px)
and (min-device-height: 812px)
and (-webkit-device-pixel-ratio: 3)
and (orientation: portrait) {
  .DialogWriteBar {
    padding-bottom: 42px;
  }
  .MessagesList {
  }
}

/* iPhone XR */
@media only screen
and (min-device-width: 414px)
and (min-device-height: 896px)
and (-webkit-device-pixel-ratio: 2)
and (orientation: portrait) {
  .DialogWriteBar {
    padding-bottom: 42px;
  }
  .MessagesList {
  }
}

.DialogWriteBar {
  flex: 1;
}

.InputWrapper {
  flex: 1;
}

.DialogWriteBar .Input {
  margin: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  box-shadow: none;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
  height: 36px;
  line-height: 22px;
  font-size: 17px;
  color: var(--text_primary);
  padding: 12px;
  position: relative;
  z-index: 2;
  background: var(--input_background);
  border: 1px solid var(--input_border);
  border-radius: 18px;
}

.AttachButton {
  width: 24px;
  height: 36px;
  padding: 0;
  margin: 0 12px;
  background: transparent;
}

.AttachButton .Button__content {
  padding: 0;
}

.SendButton {
  color: var(--accent);
  margin: 0 12px;
}