.CommissionSum {
  color: var(--text_primary);
  font-size: 16px;
  font-weight: 600;
  height: 36px;
}

.CommissionSum.Total {
  margin-bottom: 100px;
}

.CommissionSum.Total .Cell__main,
.CommissionSum.Total .Cell__indicator {
  padding: 5px 0 0;
}

.CommissionSum .Cell__indicator {
  color: var(--text_primary);
}

.CommissionSmallCell .Cell__main {
  padding: 5px 0;
}

.CommissionSmallCell .Cell__children {
  font-size: 13px;
}

.CommissionSmallCell .Cell__indicator {
  padding: 0;
  color: var(--text_primary);
  font-size: 13px;
}

.CommissionButton {
  margin-top: 40px;
}

.urlCommissionButton {
  text-decoration: none
}
