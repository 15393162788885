.TermsCooperation-Title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.TermsCooperation-Text {
  margin: 8px 0 32px;
  font-size: 14px;
  color: var(--text_subhead);
  text-align: center;
}
