.FeedbackUser{
  margin-top: 12px;
  margin-bottom: 12px;
}

.FeedbackUserStars {
  display: flex;
  align-items: center;
}

.FeedbackUserStarsTitle {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  color: var(--text_secondary);
}

.FeedbackUserStars {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

}

.FeedbackUserStar:not(:first-child) {
  margin-left: 18px;
}

.FeedbackUserStar {
  width: 40%;
}