.CardProfileTests {
    display: block;
}

.CardProfileTests__About {
    padding: 12px 12px 0 12px;
    height: 40px;
    overflow-x:hidden;
}

.CardProfileTests__Answers{
    float: right;
}