.ProfilePortfolioGroup {
  min-height: 1000px;
}

.ProfilePortfolio {
  padding: 0 12px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  height: 300px;
}

.ProfilePortfolio img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 12px;
}