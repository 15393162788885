.MyTestCard {
    overflow: hidden;
    position: relative;
}

.MyTestCard__lines{
    position: absolute;
    background: linear-gradient(-45deg,rgba(0, 0, 0, 0) 48%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 52%);
    background-size: 1em 1em;
    /*background-color: #fff;*/
    /*background-position: top;*/
    width: 100%;
    height: 100%;
    z-index: 999999;
    opacity: 0.6;
}