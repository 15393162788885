.Tabbar {
  border-top: 1px solid var(--action_sheet_separator);
}

.CardBanner .Card {
  background: var(--header_background);
}

.CardBanner {
}
