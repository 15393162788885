.MyCard {
  margin-bottom: 12px;
}

.MyCard__card {
  position: relative;
  padding-bottom: 12px;
}

.MyCard__image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 234px;
  border-radius: 10px 10px 0 0;
}

.MyCard__content {
  padding: 12px 16px;
}

.MyCard__content > div:not(:last-child){
  margin-bottom: 4px;
}

.MyCard__content .Button {
  padding: 0;
}

.MyCard__title {
  font-weight: 600;
  font-size: 17px;
}

.MyCard__text {
  font-size: 15px;
}

.MyCard__date {
  color: var(--content_placeholder_text);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.MyCard__button {
  position: absolute;
  right: 12px;
  top: 12px;
  color: var(--accent);
}

.MyTestCard__result {
  position: absolute;
  background-color: var(--accent);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  right: 12px;
  top: 12px;
  color: white;
}

.MyTestCard__try {
  position: absolute;
  background-color: var(--content_placeholder_text);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  right: 12px;
  top: 12px;
  color: white;
}
