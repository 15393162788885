.SettingsList {
  /*height: calc(100vh - 197px);*/
  overflow: auto;
}

.SettingsNotification .Group__description {
  padding-bottom: 16px;
}

.SettingsBanner .Banner__actions {
  margin: 12px 0 0;
}

.SettingsButton {
  padding: 12px;
}

.SettingsPlaceholder a {
  text-decoration: none;
  color: var(--text_link)
}
