.RequestPage__Header > div:not(:last-child){
  margin-bottom: 4px;
}

.RequestPage__Header {
  padding: 12px 16px;
}

.RequestPage__Date {
  color: var(--content_placeholder_text);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.RequestPage__Title {
  font-weight: 600;
  font-size: 17px;
}

.RequestPage__Text {
  font-size: 15px;
}

.RequestPage__Files {
  margin-bottom: 70px;
}

.RequestPage__Image img {
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(var(--background_highlighted));
}
