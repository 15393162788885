.placeholder span{
    color: black;
}

.group {
    display: grid;
    place-items: center;
}

.cardGrid {
    max-width: 400px;
}
