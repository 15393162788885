.RequestCard {
  position: relative;
}

.RequestCard__image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 234px;
}

.RequestCard__imageVideo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.RequestCard__videoWrapper{
  position: relative;
  padding-bottom: 56.25%;
}

.RequestCard__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.RequestCard__content {
  padding: 12px 16px;
}

.RequestCard__content > div:not(:last-child){
  margin-bottom: 4px;
}

.RequestCard__content .Button {
  padding: 0;
}

.RequestCard__title {
  font-weight: 600;
  font-size: 17px;
}

.RequestCard__text {
  font-size: 15px;
}

.RequestCard__date {
  color: var(--content_placeholder_text);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.RequestCard__button {
  position: absolute;
  right: 12px;
  top: 12px;
  color: var(--accent);
}
