.PaymentSettings .FormLayout__row {
  padding: 0;
}

.PaymentInfo {
  padding: 0 26px 12px;
  font-size: 15px;
}

.PaymentInfo__Author {
  color: var(--content_placeholder_text);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
}

.PaymentInfo__Date {
  color: var(--content_placeholder_text);
  font-weight: 600;
  font-size: 13px;
}

.PaymentSettings__Button {
  margin-top: 24px;
  padding-bottom: 24px;
}
