.titleText {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.textText {
  margin: 8px 0 32px;
  font-size: 14px;
  color: var(--text_subhead);
  text-align: center;
}

.textText a {
  text-decoration: none;
  color: var(--accent);
}