.AffiliateCell.SimpleCell {
  text-transform: uppercase;
  color: var(--text_muted);
  font-weight: 600;
  font-size: 13px;
  min-height: 36px;
  height: 36px;
  padding-right: 12px;
}

.AffiliateItem.RichCell {
  align-items: center;
}

/*.AffiliateItem.confirm .RichCell__after {*/
/*  color: var(--text_tertiary);*/
/*}*/

.AffiliateItem .Icon {
  margin-right: 12px;
  color: var(--accent);
}

