.ModalCardProInfo-Title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.ModalCardProInfo-Text {
  margin-top: 8px;
  font-size: 14px;
  color: var(--text_subhead);
  text-align: center;
}
