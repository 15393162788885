.ModalCardCreateChat-Title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-top: 18px;

}

.ModalCardCreateChat-Text {
  margin-top: 8px;
  font-size: 14px;
  color: var(--text_primary);
  text-align: center;
  margin-bottom: 24px;
}
