.userInfoAvatar56 {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 28px;
    display: grid;
    place-items: center;
    font-weight: normal;
}

.userInfoAvatar48 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 24px;
    display: grid;
    place-items: center;
    font-weight: normal;
}

.userInfoAvatar36 {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    display: grid;
    place-items: center;
    font-weight: normal;
}

.userInfoAvatar32 {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 16px;
    display: grid;
    place-items: center;
    font-weight: normal;
}

.userInfoAvatarIsOnline{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 10px;
    height: 10px;
    background-color: #4bb34b;
    border-radius: 50%;
    margin-left: 72%;
    margin-top: 72%;
    border: 3px solid white;
}

.userInfoAvatarIsOnlinebright_light{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 10px;
    height: 10px;
    background-color: #4bb34b;
    border-radius: 50%;
    margin-left: 72%;
    margin-top: 72%;
    border: 3px solid white;
}

.userInfoAvatarIsOnlinespace_gray{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 10px;
    height: 10px;
    background-color: #4bb34b;
    border-radius: 50%;
    margin-left: 72%;
    margin-top: 72%;
    border: 3px solid #19191a;
}