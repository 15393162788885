.Gallery {
  height: calc(100vh - 85px);
  overflow: hidden;
}

.GalleryLogo {
  padding-top: 37px;
  padding-left: 21px;
  align-self: flex-start;
  width: 116px;
  height: 32px;
}

.GallerySlide .Panel__in {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

/* iPhone X and Xs Max */
@media only screen
and (min-device-width: 375px)
and (min-device-height: 812px)
and (-webkit-device-pixel-ratio: 3)
and (orientation: portrait) {
  .Gallery {
    height: calc(100vh - 120px);
  }

  .GalleryLogo {
    padding-top: 14%;
  }
}

/* iPhone XR */
@media only screen
and (min-device-width: 414px)
and (min-device-height: 896px)
and (-webkit-device-pixel-ratio: 2)
and (orientation: portrait) {
  .Gallery {
    height: calc(100vh - 120px);
  }

  .GalleryLogo {
    padding-top: 14%;
  }
}

.GalleryButton {
  position: absolute;
  bottom: 8.5%;
  width: calc(100% - 24px);
  background: var(--text_primary);
  font-size: 22px;
}
