.TestGroup__info {
    padding-top: 4px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}

.TestGroup__info span {
    color: var(--accent);
    cursor: pointer;
}

.Test__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding-bottom: 70%;
    border-radius: 10px;
}