.CategoryItem__image {
  width: 28px;
  height: 28px;
  margin-right: 14px;
}

.CategoryItem__SimpleCell.SimpleCell {
  padding-left: 12px;
}

.CategoryItem__SimpleCell:hover {
  background-color: var(--background_highlighted);
}
