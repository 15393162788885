.CommissionCheckbox .Checkbox__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CommissionCheckbox svg {
  margin-top: -5px;
}

.CommissionCheckbox .Checkbox__container {
  align-items: center;
}

.CommissionCheckbox .right {
  display: flex;
  align-items: center;
}

.left {
  color: var(--accent);
}

.right .Icon {
  padding-left: 12px;
  color: var(--accent);
}

.CommissionCheckbox__icon svg{
  margin-top: 0;
}
